import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import { ChakraProvider } from "@chakra-ui/react";
import { CookiesProvider } from "react-cookie";

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <CookiesProvider>
    <ChakraProvider>
      <Router>
        <App />
      </Router>
    </ChakraProvider>
  </CookiesProvider>
);
