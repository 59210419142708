import {
  Avatar,
  Card,
  CardHeader,
  CardBody,
  Text,
  Heading,
  Flex,
  Spacer,
  Box,
  IconButton,
} from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";

const MessageItem = (props) => {
  return (
    <Card variant="elevated" cursor="pointer" mb="2">
      <CardHeader>
        <Flex spacing="2">
          <Flex
            flex="1"
            alignItems="center"
            flexWrap="wrap"
            flexDirection="row"
            gap="4"
          >
            <Avatar name={props.name} />
            <Box ml="3">
              <Heading size="sm">{props.name}</Heading>
              <Text color="gray.600">{props.email}</Text>
            </Box>
            <Flex
              flexDirection="column"
              justifyContent="center"
              display={{ base: "none", sm: "none", md: "block" }}
              ml="auto"
              mr="auto"
            >
              <Heading size="sm">{props.title}</Heading>
              <Text color="gray.600">{props.message}</Text>
            </Flex>
          </Flex>

          <IconButton
            variant="ghost"
            colorScheme="gray"
            aria-label="See menu"
            icon={<FiChevronRight />}
          />
        </Flex>
      </CardHeader>
    </Card>
  );
};

export default MessageItem;
