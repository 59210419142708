import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { useCookies } from "react-cookie";
import Mailjs from "@cemalgnlts/mailjs";

import SidebarWithHeader from "./components/navigation/SidebarWithHeader";
import MessagesList from "./components/messagesList";
import ViewMessage from "./components/viewMessage";

import "./styles.css";

const mailjs = new Mailjs();

export default function App() {
  const [cookies, setCookie] = useCookies(["user"]);
  const [currentEmail, setCurrentEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");

  const [accountId, setAccountId] = useState("");
  const [token, setToken] = useState("");

  const updateCurrentEmail = (email) => {
    setCurrentEmail(email);
    setCookie("email", email, { path: "/" });
    console.log("APP.JS EMAIL CHANGE: ", email);
  };

  const updateCurrentData = (email, password, id, userToken) => {
    setCurrentEmail(email);
    setCurrentPassword(password);
    setAccountId(id);
    setToken(userToken);

    setCookie("email", email, { path: "/" });
    setCookie("password", password, { path: "/" });
    setCookie("id", id, { path: "/" });
    setCookie("token", userToken, { path: "/" });
    console.log("APP.JS DATA CHANGE: ", email, password);
  };

  const createEmail = async () => {
    console.log("CREATE EMAIL FUNCTION RUN");
    setCurrentEmail("");
    let result = await mailjs.createOneAccount();

    while (result.data.username === undefined) {
      result = await mailjs.createOneAccount();
    }

    updateCurrentData(
      result.data.username,
      result.data.password,
      mailjs.id,
      mailjs.token
    );
    console.log(
      "APP.JS NEW EMAIL:",
      result.data.username,
      mailjs.id,
      mailjs.token
    );
  };

  const checkLogin = async () => {
    if (cookies.email !== undefined || cookies.password !== undefined) {
      const res = await mailjs.login(cookies.email, cookies.password);
      updateCurrentData(
        cookies.email,
        cookies.password,
        cookies.id,
        cookies.token
      );

      console.log("LOGGED IN", mailjs.token);
      console.log(res);
    } else {
      createEmail();
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <div className="App">
      <SidebarWithHeader
        mailJS={mailjs}
        currentEmail={currentEmail}
        currentPassword={currentPassword}
        updateCurrentData={updateCurrentData}
        createEmail={createEmail}
      >
        <Routes>
          <Route
            exact
            path="/"
            element={
              <MessagesList
                mailJS={mailjs}
                currentEmail={currentEmail}
                updateCurrentEmail={updateCurrentEmail}
                accountId={accountId}
                token={token}
              />
            }
          ></Route>
          <Route
            path="/view/:id"
            element={
              <ViewMessage mailJS={mailjs} currentEmail={currentEmail} />
            }
          ></Route>
        </Routes>
      </SidebarWithHeader>
    </div>
  );
}
