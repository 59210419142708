import React, { useState, useEffect } from "react";

import {
  IconButton,
  Flex,
  useColorModeValue,
  useToast,
  Tag,
  Text,
  FlexProps,
  Skeleton,
} from "@chakra-ui/react";
import { FiMenu, FiRefreshCw, FiCopy } from "react-icons/fi";

import { useCookies } from "react-cookie";

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({
  onOpen,
  mjs,
  currentEmail,
  currentPassword,
  updateCurrentData,
  createEmail,
  ...rest
}: MobileProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, setCookie] = useCookies(["user"]);

  const toast = useToast();

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Flex mr="auto" alignItems="center">
        <Skeleton isLoaded={currentEmail !== ""} ml="3">
          <Tag display={{ base: "flex", md: "6" }} fontSize="lg" mr={2}>
            {currentEmail == "" ? "abcdef@preteer.com" : currentEmail}
          </Tag>
        </Skeleton>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          minW={8}
          icon={<FiCopy />}
          onClick={() => {
            navigator.clipboard.writeText(currentEmail);
            toast({
              title: "Email Copied To Clipboard",
              status: "success",
              duration: 1750,
              isClosable: true,
            });
          }}
        />
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          minW={8}
          icon={<FiRefreshCw />}
          onClick={() => {
            setIsLoading(true);
            createEmail();
            setIsLoading(false);
          }}
        />
      </Flex>
    </Flex>
  );
};

export default MobileNav;
