import React, { useState, useEffect } from "react";
import { EventSourcePolyfill } from "event-source-polyfill";
import { Link } from "react-router-dom";

import {
  Avatar,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Text,
  Stack,
  Heading,
  Flex,
  Box,
  IconButton,
} from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { useCookies } from "react-cookie";

import MessageItem from "./messageItem";
import NoMessages from "./noMessages";

const MessagesList = ({
  mailJS,
  currentEmail,
  updateCurrentEmail,
  accountId,
  token,
  ...rest
}) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (!accountId || !token) {
      return; // No need to proceed if accountId or token is missing
    }

    const baseUrl = "https://mercure.mail.tm/.well-known/mercure";
    const topic = `/accounts/${accountId}`;

    const eventSourceInitDict = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const eventSource = new EventSourcePolyfill(
      `${baseUrl}?topic=${topic}`,
      eventSourceInitDict
    );

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const type = data["@type"];

      if (type === "Message") {
        console.log(data);
        setMessages((prevMessages) => [data, ...prevMessages]);
      }
    };

    eventSource.onerror = (err) => {
      console.log("EventSource error: ", err);
    };

    return () => {
      eventSource.close();
    };
  }, [accountId, token]);

  const getMessages = async () => {
    if (currentEmail !== "") {
      let res = await mailJS.getMessages();

      if (res.data == messages) {
        console.log("No need update");
      } else {
        setMessages(res.data);
      }
    } else {
      console.log(`getMessages FAIL: "${currentEmail}"`);
    }
  };

  useEffect(() => {
    if (!currentEmail) {
      return;
    }

    getMessages();
  }, [currentEmail]);

  return (
    <Stack spacing="4">
      {/* <MessageItem
        email="noreply@openai.com"
        name="OpenAI"
        title="Your AVMail Verification Code"
        message="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque condimentum, ligula et posuere condimentum, nisi ipsum euismod lacus, eu aliquet sem tellus finibus purus. Sed."
      /> */}
      {messages.length > 0 ? (
        messages.map((msg) => (
          <Link to={`/view/${msg.id}/`}>
            <MessageItem
              key={msg.id}
              email={msg.from.address}
              name={msg.from.name}
              title={msg.subject}
              message={msg.intro}
            />
          </Link>
        ))
      ) : (
        <NoMessages />
      )}
    </Stack>
  );
};

export default MessagesList;
