import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Flex,
  Avatar,
  Text,
  Link,
  AspectRatio,
  Image,
  Icon,
  Skeleton,
} from "@chakra-ui/react";
import { ChevronLeftIcon, EmailIcon } from "@chakra-ui/icons";

function ViewMessage({ mailJS, currentEmail, ...rest }) {
  let { id } = useParams();
  const [messageContents, setMessageContents] = useState({
    "@context": "/contexts/Message",
    "@id": "/messages/64e19d32b5da06befd32efa6",
    "@type": "Message",
    id: "",
    accountId: "",
    msgid: "",
    from: {
      address: "",
      name: "",
    },
    to: [
      {
        address: "",
        name: "",
      },
    ],
    subject: "",
    intro: "",
    seen: false,
    isDeleted: false,
    hasAttachments: false,
    downloadUrl: "",
    size: 14473,
    createdAt: "",
    updatedAt: "",
  });

  const getMessageContents = async () => {
    const res = await mailJS.getMessage(id);
    setMessageContents(res.data);
    console.log(res.data);
  };

  useEffect(() => {
    if (!currentEmail) {
      return;
    }

    getMessageContents();
  }, [currentEmail]);

  return (
    <>
      <Box>
        <nav className="text-sm font-medium leading-5">
          <RouterLink to="/">
            <Icon
              as={ChevronLeftIcon}
              fill="currentColor"
              viewBox="0 0 20 20"
              className="flex-shrink-0 -ml-1 mr-1 w-5 h-5 text-gray-400"
            />
            Back
          </RouterLink>
        </nav>
        <Skeleton isLoaded={messageContents.subject !== ""} height="30px">
          <Box flex="1" minW={0}>
            <Text
              color="gray.900"
              fontSize="2xl"
              fontWeight="bold"
              leading="7"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {messageContents.subject}
            </Text>
          </Box>
        </Skeleton>
      </Box>

      <Box
        mt={4}
        px={4}
        py={5}
        bg="white"
        borderBottom="1px"
        borderColor="gray.200"
        shadow="md"
        overflow="hidden"
        rounded="md"
      >
        <Flex
          wrap="wrap"
          alignItems="center"
          justifyContent="space-between"
          ml={-4}
          mt={-4}
        >
          <Flex ml={4} mt={4} alignItems="center">
            <Box>
              <Avatar size="lg" name={messageContents.from.name} />
            </Box>
            <Box ml={4}>
              <Skeleton
                isLoaded={messageContents.from.name !== ""}
                width="300px"
              >
                <Flex
                  direction={{ base: "column", sm: "row" }}
                  alignItems="center"
                >
                  <Text
                    color="blue.600"
                    fontSize="lg"
                    fontWeight="medium"
                    lineHeight="tight"
                  >
                    {messageContents.from.name}&nbsp;
                  </Text>
                  <Text
                    color="gray.700"
                    fontSize="sm"
                    fontWeight="normal"
                    lineHeight="tight"
                    as="span"
                  >
                    &lt;{messageContents.from.address}&gt;
                  </Text>
                </Flex>
              </Skeleton>
              <Flex
                alignItems="center"
                mt={2}
                color="gray.500"
                fontSize="sm"
                lineHeight="tight"
              >
                <EmailIcon boxSize={5} mr={1} color="gray.400" />
                <Skeleton
                  isLoaded={messageContents.from.name !== ""}
                  width="150px"
                >
                  <Text isTruncated>
                    <Link href={`mailto:${currentEmail}`} ml={1}>
                      {currentEmail}
                    </Link>
                  </Text>
                </Skeleton>
              </Flex>
            </Box>
          </Flex>
        </Flex>
        <Box px={4} py={5}>
          <AspectRatio ratio={16 / 8}>
            <iframe srcDoc={messageContents.html} />
          </AspectRatio>
        </Box>
      </Box>

      <div>Viewing content with id: {id}</div>
    </>
  );
}

export default ViewMessage;
